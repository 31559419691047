const state = {
  token: null
};

const mutations = {
  setToken(state, token) {
    state.token = token;

    const tokenKey = process.env.VUE_APP_TOKEN_KEY;

    if (token)
      localStorage.setItem(tokenKey, token);
    else
      localStorage.removeItem(tokenKey);
  }
};

export default {
  namespaced: true, state, mutations
};
