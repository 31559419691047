<template>
  <div>
    <RouterView />
  </div>
</template>

<script>
import sessionRecoveryController from "@/controllers/session-recovery.controller";

export default {
  name: 'App',
  async mounted() {
    await sessionRecoveryController.recovery();
  }
};
</script>
