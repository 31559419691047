import axiosClient from "@/services/axios-client";

class LoginApi {
  alterPassword(data) {
    return axiosClient.post('login/alter-password', data);
  }

  getMe() {
    return axiosClient.get('login/me');
  }

  login(credentials) {
    return axiosClient.post('login', credentials);
  }

  resetPassword(email) {
    return axiosClient.post('login/reset-password', { email });
  }
}

export default new LoginApi();
