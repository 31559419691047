import { createStore } from 'vuex'
import userStore from "@/store/modules/user.store";
import tokenStore from "@/store/modules/token.store";
import enterpriseStore from "@/store/modules/enterprise.store";

export default createStore({
  modules: {
    enterpriseStore,
    tokenStore,
    userStore,
  },
  strict: false,
  plugins: []
})
