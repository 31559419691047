const state = {
  user: null
};

const mutations = {
  setUser(state, user) {
    state.user = user;
    if (user) localStorage.setItem('user', JSON.stringify(user));
  }
};

const getters = {
  user: (state) => {
    if (!state.user) {
      const user = localStorage.getItem('user');
      if (user) state.user = JSON.parse(user);
    }

    return state.user
  }
};

export default {
  namespaced: true, state, mutations, getters
};
