import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'splash',
    component: () => import('./pages/splash/SplashPage')
  },
  {
    path: '/create-account',
    name: 'create-account',
    component: () => import('./pages/create-account/CreateAccountPage')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('./pages/home/HomePage'),
    children: [
      {
        path: '',
        component: () => import('./pages/home/components/EmptyHome')
      },
      {
        path: '/list/:listName',
        component: () => import('./pages/list/ListPage')
      },
      {
        path: '/form/:formName',
        component: () => import('./pages/form/FormPage')
      },
      {
        path: '/form/:formName/:id',
        component: () => import('./pages/form/FormPage')
      },
    ]
  },
  {
    path: '/logoff',
    name: 'logoff',
    component: () => import('./pages/logoff/LogoffPage')
  },
  {
    path: '/login/:publicId',
    name: 'login-with-public-id',
    component: () => import('./pages/login/LoginPage')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/login/LoginPage')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('./pages/reset-password/ResetPasswordPage')
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: routes
});

export default router;
