import { ElNotification } from "element-plus";

class Notificator {
  error(message) {
    this._notify('error', message.message || message);
  }

  info(message) {
    this._notify('info', message);
  }

  success(message) {
    this._notify('success', message);
  }

  _notify(type, message) {
    if (!message) return;

    let title = 'Sucesso';

    switch (type) {
      case 'error':
        title = 'Erro';
        break;
      case 'info':
        title = 'Informação';
        break;
    }

    ElNotification({ title, message, type: type, });
  }
}

export default new Notificator();
