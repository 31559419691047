import axios from 'axios';
import notificator from "@/services/notificator";
import router from "@/router";

const baseURL = process.env.VUE_APP_API_URL;
const tokenKey = process.env.VUE_APP_TOKEN_KEY;

const axiosClient = axios.create({ baseURL });

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem(tokenKey);

  if (token) {
    config.headers.authorization = token;
  }

  return config;
});

axiosClient.interceptors.response.use((response) => {
  return response.data ?? response;
}, (error) => {
  const message = error.response.data?.message;

  if (error.response.status === 401) {
    router.replace('login');
  } else {
    notificator.error(message);
  }

  const validators = error.response?.data?.validators;

  if (validators) {
    for (const validatorsKey in validators) {
      validators[validatorsKey] = validators[validatorsKey].join(' - ');
    }

    error.response.data.validators = validators;
  }

  return Promise.reject(error.response.data);
});

export default axiosClient;
