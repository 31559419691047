const state = {
  enterprise: null
};

const mutations = {
  setEnterprise(state, enterprise) {
    state.enterprise = enterprise;
    if (enterprise) localStorage.setItem('enterprise', JSON.stringify(enterprise));
  }
};

const getters = {
  enterprise: (state) => {
    if (!state.enterprise) {
      const enterprise = localStorage.getItem('enterprise');
      if (enterprise) state.enterprise = JSON.parse(enterprise);
    }

    return state.enterprise
  }
};

export default {
  namespaced: true, state, mutations, getters 
};
