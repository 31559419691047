import loginApi from "@/api/login.api";
import store from "@/store";
import router from "@/router";

class SessionRecoveryController {
  async recovery() {
    if (!router.currentRoute?.name) return
    const ignoredRoutes = ['reset-password', 'login', 'login-with-public-id']
    if (ignoredRoutes.includes(router.currentRoute.name)) return

    const tokenKey = process.env.VUE_APP_TOKEN_KEY;
    const token = localStorage.getItem(tokenKey);

    if (!token) {
      return router.replace('login');
    }

    const response = await loginApi.getMe();

    store.commit('enterpriseStore/setEnterprise', response.enterprise);
    store.commit('userStore/setUser', response.user);
  }
}

export default new SessionRecoveryController();
